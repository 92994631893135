const consentCookieName = 'RBXcb';
const consentExpirationDays = 180;
const cookieBannerContent = 'Description.CookieBannerContent';
const declineBtnText = 'Action.DeclineAll';
const acceptBtnText = 'Action.AcceptAll';
const privacyPolicy = 'Description.PrivacyPolicy';
const privacyPolicyLinkPlaceholder = '{privacyPolicyLink}';
const cookieConsent = 'Action.SetCookieOptions';

export default {
  consentCookieName,
  consentExpirationDays,
  cookieBannerContent,
  declineBtnText,
  acceptBtnText,
  privacyPolicy,
  privacyPolicyLinkPlaceholder,
  cookieConsent
};
